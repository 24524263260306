import React from "react";
import { Link } from "gatsby";

import { useTranslation } from "react-i18next";

const Pricing = () => {
  
const { t, i18n } = useTranslation();
  return (
    <div className="pb-5 pb-md-11 pb-lg-19">
      <div className="container">
        <div className="row justify-content-center">
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={300}
              data-aos-once="true"
            >
              <p className="text-gray font-size-5 mb-9">
                {t("memberships_subtitle1")}</p>
              <h2 className="font-size-11 mb-1">{t("memberships_heading1")}</h2>
              <span className="font-size-5 mb-2">{t("memberships_subheading1")}</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">{t("memberships_paragraph1")}</li>
                <li className="mb-7">{t("memberships_paragraph3")} {t("memberships_paragraph2")}</li>
                <li className="mb-7">{t("memberships_paragraph7")} {t("memberships_paragraph6")}</li>
                <li className="mb-7">{t("memberships_paragraph10")} {t("memberships_paragraph9")}</li>
                <li className="mb-7 text-stone">
                  <del>{t("memberships_paragraph12")}</del>
                </li>
              </ul>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={600}
              data-aos-once="true"
            >
              <h1 className="font-size-8 mb-9 text-dark-digi">{t("memberships_subtitle2")}</h1>
              <h2 className="font-size-8 mb-1"><strike>{t("memberships_heading2_1")}</strike>{t("memberships_filler1")}</h2>
              <h2 className="font-size-10 mb-1 text-light-digi">{t("memberships_heading2")}*</h2>
              <span className="font-size-5 mb-2">{t("memberships_filler3")} <strike>{t("memberships_subheading2_1")}</strike>{t("memberships_filler2")}</span><br/>
              <span className="font-size-5 mb-2 text-red">{t("memberships_subheading2")}{t("memberships_filler2")}*</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">{t("memberships_paragraph1")}</li>
                <li className="mb-7"><b>{t("memberships_paragraph4")}</b> {t("memberships_paragraph2")}</li>
                <li className="mb-7">{t("memberships_paragraph7")} {t("memberships_paragraph6")}</li>
                <li className="mb-7"><b>{t("memberships_paragraph11")}</b> {t("memberships_paragraph9")}</li>
                <li className="mb-7"><b>{t("memberships_paragraph13")}</b></li>                
                <li className="mb-7"><b>{t("memberships_paragraph14")}</b></li>
                <li className="mb-7"><b>{t("memberships_paragraph15")}</b></li>
                <li className="mb-7"><b>{t("memberships_paragraph16")}</b></li>
                <li ><i>{t("memberships_paragraph18")}</i></li>
              </ul>
              <div className="pt-7 pt-lg-17">
              <Link to="/comingsoon"><a className="btn btn-golden-yellow btn-2 rounded-5">
                {t("memberships_button")}
                </a>
                </Link>
              </div>
            </div>
          </div>
          {/* End Single Table */}
     {/*     
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={900}
              data-aos-once="true"
            >
              <p className="font-size-5 mb-9 text-light-digi">{t("memberships_subtitle3")}</p>
              <h2 className="font-size-11 mb-1">{t("memberships_heading3")}</h2>
              <span className="font-size-5 mb-2">{t("memberships_subheading3")}</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">{t("memberships_paragraph1")}</li>
                <li className="mb-7"><b>{t("memberships_paragraph5")}</b> {t("memberships_paragraph2")}</li>
                <li className="mb-7"><b>{t("memberships_paragraph8")}</b> {t("memberships_paragraph6")}</li>
                <li className="mb-7">{t("memberships_paragraph11")} {t("memberships_paragraph9")}</li>
                <li className="mb-7">{t("memberships_paragraph13")}</li>                
                <li className="mb-7">{t("memberships_paragraph14")}</li>
                <li className="mb-7">{t("memberships_paragraph15")}</li>
                <li className="mb-7">{t("memberships_paragraph16")}</li>
                <li className="mb-7"><b>{t("memberships_paragraph17")}</b></li>
                <li ><i>{t("memberships_paragraph18")}</i></li>
              </ul>
              <div className="pt-7 pt-lg-17">
                <Link to="/comingsoon"><a className="btn btn-golden-yellow btn-2 rounded-5">
                {t("memberships_button")}
                </a>
                </Link>
              </div>
            </div>
          </div>
         */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
